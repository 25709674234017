import { PanelPlugin, FieldConfigProperty, FieldColorModeId } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components';
import { ColorPicker } from '@grafana/ui';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel)
  .useFieldConfig({
    disableStandardOptions: [
      // FieldConfigProperty.Color,
      // FieldConfigProperty.Decimals,
      FieldConfigProperty.DisplayName,
      FieldConfigProperty.Filterable,
      FieldConfigProperty.Links,
      FieldConfigProperty.Mappings,
      FieldConfigProperty.Max,
      FieldConfigProperty.Min,
      FieldConfigProperty.NoValue,
      FieldConfigProperty.Thresholds,
      FieldConfigProperty.Unit,
    ]
  })
  .setPanelOptions((builder) => {
    return builder
      .addTextInput({
        path: 'variableName',
        name: 'Variable'
      })
      .addTextInput({
        path: 'defaultValue',
        name: 'Default Value'
      })
      .addSelect({
        path: 'inputType',
        name: 'Type of Input',
        defaultValue: 'select',
        settings: {
          options: [
            {
              value: 'select',
              label: 'Select'
            },
            {
              value: 'multiselect',
              label: 'MultiSelect'
            },
            {
              value: 'input',
              label: 'Input'
            },
            {
              value: 'throughputlist',
              label: 'Throughput List'
            }
          ]
        },
      })
      .addSelect({
        path: 'dataSource',
        name: 'Data Source',
        defaultValue: 'query',
        settings: {
          options: [
            {
              value: 'query',
              label: 'Query'
            },
            {
              value: 'list',
              label: 'List'
            }
          ]
        },
        showIf(currentOptions) {
          return currentOptions.inputType === 'select' || currentOptions.inputType === 'multiselect'
        }
      })
      .addTextInput({
        path: 'optionsCSV',
        name: 'Options',
        showIf(currentOptions) {
          if (currentOptions.inputType !== 'select' && currentOptions.inputType !== 'multiselect') {
            return false;
          }
          return currentOptions.dataSource === 'list';
        },
        settings: {
          useTextarea: true
        },
      })
      .addSelect({
        path: 'queryRefId',
        name: 'Query',
        showIf(currentOptions) {
          if (currentOptions.inputType !== 'select' && currentOptions.inputType !== 'multiselect') {
            return false;
          }
          return currentOptions.dataSource === 'query';
        },
        settings: {
          options: [],

          getOptions(context) {
            return Promise.resolve(context.data.map(({ refId, name }) => ({
              value: refId,
              label: name
            })));
          }
        },
      })
      .addTextInput({
        path: 'queryFieldName',
        name: 'Label Name',
        showIf(currentOptions) {
          return (currentOptions.inputType === 'select' || currentOptions.inputType === 'multiselect') && currentOptions.dataSource === 'query';
        }
      })
      .addTextInput({
        path: 'primaryField',
        name: 'Primary Name',
        showIf(currentOptions) {
          return currentOptions.inputType === 'throughputlist';
        }
      })
      .addTextInput({
        path: 'sortVariable',
        name: 'Sort Variable',
        showIf(currentOptions) {
          return currentOptions.inputType === 'throughputlist';
        }
      })
      .addNumberInput({
        path: 'limitRows',
        name: 'Limit Rows',
        showIf(currentOptions) {
          return currentOptions.inputType === 'throughputlist';
        }
      })
  });
